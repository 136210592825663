"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDashboard = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _tutorial = require("./tutorial");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getDashboard {\n    getDashboardInfo(\n      getDashboardInfo: {\n        pendingOrder: true\n        notShipped: true\n        orderQA: true\n        productQA: true\n        userCount: true\n        orderMonthly: true\n        revenueMonthly: true\n        costMonthly: true\n        numberOfRewardPointReminderSentMonthly: true\n        numberOfCartReminderSentMonthly: true\n      }\n    ) {\n      pendingOrder\n      notShipped\n      orderQA\n      productQA\n      userCount\n      orderMonthly\n      revenueMonthly\n      numberOfRewardPointReminderSentMonthly\n      numberOfCartReminderSentMonthly\n    }\n\n    viewer {\n      id\n      role\n      store {\n        id\n        description {\n          name\n        }\n        unpaidBills {\n          totalCount\n        }\n        bills(first: 1) {\n          edges {\n            node {\n              id\n              payment {\n                status\n              }\n            }\n          }\n        }\n        setting {\n          ...tutorialSettingObjectTypeFragment\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var getDashboard = (0, _client.gql)(_templateObject(), _tutorial.tutorialSettingObjectTypeFragment);
exports.getDashboard = getDashboard;
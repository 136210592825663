"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TUTORIAL = void 0;

var _ShopOutlineIcon = _interopRequireDefault(require("@meepshop/icons/lib/ShopOutlineIcon"));

var _PageDesignOutlineIcon = _interopRequireDefault(require("@meepshop/icons/lib/PageDesignOutlineIcon"));

var _ProductManageOutlineIcon = _interopRequireDefault(require("@meepshop/icons/lib/ProductManageOutlineIcon"));

var _CashFlowOutlineIcon = _interopRequireDefault(require("@meepshop/icons/lib/CashFlowOutlineIcon"));

var _ShippingOutlineIcon = _interopRequireDefault(require("@meepshop/icons/lib/ShippingOutlineIcon"));

var _CustomPageFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/CustomPageFilledIcon"));

var _OnePageStoreFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/OnePageStoreFilledIcon"));

var _VirtualPaymentFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/VirtualPaymentFilledIcon"));

var _CreditCardFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/CreditCardFilledIcon"));

var _PayAfterFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/PayAfterFilledIcon"));

var _HomeDeliveryFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/HomeDeliveryFilledIcon"));

var _ConvinienceStoreFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/ConvinienceStoreFilledIcon"));

var _CustomShippingFilledIcon = _interopRequireDefault(require("@meepshop/icons/lib/CustomShippingFilledIcon"));

// import
// definition
var TUTORIAL = [{
  key: 'store',
  link: '/setting/store',
  Icon: _ShopOutlineIcon["default"]
}, {
  key: 'page',
  items: [_CustomPageFilledIcon["default"], _OnePageStoreFilledIcon["default"]],
  link: '/page-manager',
  Icon: _PageDesignOutlineIcon["default"]
}, {
  key: 'product',
  link: '/products',
  Icon: _ProductManageOutlineIcon["default"]
}, {
  key: 'payment',
  items: [_VirtualPaymentFilledIcon["default"], _CreditCardFilledIcon["default"], _PayAfterFilledIcon["default"]],
  link: '/payments',
  Icon: _CashFlowOutlineIcon["default"]
}, {
  key: 'shipment',
  items: [_HomeDeliveryFilledIcon["default"], _ConvinienceStoreFilledIcon["default"], _CustomShippingFilledIcon["default"]],
  link: '/shippings',
  Icon: _ShippingOutlineIcon["default"]
}];
exports.TUTORIAL = TUTORIAL;
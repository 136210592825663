"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminSetIsTutorialEnabled = exports.tutorialStoreFragment = exports.tutorialSettingObjectTypeFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  mutation adminSetIsTutorialEnabled($input: SetIsTutorialEnabledInput!) {\n    setIsTutorialEnabled(input: $input) {\n      success\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment tutorialStoreFragment on Store {\n    id\n    setting {\n      isTutorialEnabled\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment tutorialSettingObjectTypeFragment on SettingObjectType {\n    isTutorialEnabled\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var tutorialSettingObjectTypeFragment = (0, _client.gql)(_templateObject());
exports.tutorialSettingObjectTypeFragment = tutorialSettingObjectTypeFragment;
var tutorialStoreFragment = (0, _client.gql)(_templateObject2());
exports.tutorialStoreFragment = tutorialStoreFragment;
var adminSetIsTutorialEnabled = (0, _client.gql)(_templateObject3());
exports.adminSetIsTutorialEnabled = adminSetIsTutorialEnabled;